async function fetchDataFromGoogleSheets(url) {
  try {
    const response = await fetch(url);
    const data = await response.text(); // Assuming CSV format

    return data;
  } catch (error) {
    console.error("Error fetching data from Google Sheets:", error);
    throw error;
  }
}
export function handleData(data) {
  if (Array.isArray(data)) {
    // Handle array of arrays directly
    return data.map((row) => {
      return row.map((cell) => cell.trim());
    });
  }

  if (typeof data === "string") {
    // Handle string as CSV data
    const lines = data.split("\n");
    const parsedData = lines.map((line) => {
      return line.split(",").map((cell) => cell.trim());
    });
    return parsedData;
  }

  throw new TypeError("Data must be a string or an array");
}

export function parseData(data) {
  const lines = data.split("\n");
  const headers = lines[0].split(",").map((header) => header.trim());
  const parsedData = [];

  for (let i = 1; i < lines.length; i++) {
    const values = lines[i].split(",");
    const entry = {};

    // Skip the line if the number of values is less than the number of headers
    if (values.length !== headers.length) {
      // If the number of values is less than headers, fill in missing values with empty strings
      while (values.length < headers.length) {
        values.push("");
      }
    }

    headers.forEach((header, index) => {
      const key = header;
      const value = values[index] ? values[index].trim() : ""; // Check if value exists, otherwise assign an empty string
      entry[key] = { value }; // Create an object for each column
    });

    parsedData.push(entry);
  }

  return parsedData;
}

async function insertDataIntoHomePage(url) {
  try {
    const data = await fetchDataFromGoogleSheets(url);

    const extractedData = parseData(data);
    // Insert extracted data into your home page
    // For example, render the data in a template or use it to dynamically generate content

    return extractedData; // Return the parsed data
  } catch (error) {
    // Handle errors
    console.error("Error inserting data into home page:", error);
  }
}

export { fetchDataFromGoogleSheets, insertDataIntoHomePage };
