import React, { useRef, useEffect, useState } from "react";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import Sparkle from "react-sparkle";

const Offers = ({ offerTitle, offerContent }) => {
  const offerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = offerRef.current;
      const top = element.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      let haveSeen = false;

      if (!haveSeen) {
        if (top < windowHeight) {
          setIsVisible(true);
          haveSeen = true;
        }
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`offers__container ${isVisible ? "animated" : ""}`}
      ref={offerRef}
    >
      <h1 className="offer__title glitter">{offerTitle.toUpperCase()}</h1>

      <div style={{ position: "relative" }}>
        {/*<Sparkle
          pos
          count={25}
          fadeOutSpeed={5}
          flicker={true}
          flickerSpeed={"slowest"}
        />*/}
        <p className="offer__content">
          {offerContent}
          <DirectionsCarIcon />
        </p>
      </div>
    </div>
  );
};

export default Offers;
